import React, { useEffect, useState } from "react";
import * as BlinkIDSDK from "@microblink/blinkid-in-browser-sdk";
import { Text, View } from "react-native-web";

const licenseKey =
  "sRwAAAYPa2F6aS52ZXJjZWwuYXBw3ElN4ZVG+c7j+faO/S59gYp6ovnqLyh0u5YSDY9193NaVQO546bAu28qa6olA6/HS22UDoJrvMqC+ijWYbqawP0DjjL/LGz1/kiUtPrQWxHF5l1G+qETxwqkDXAE3+tlnFp9UVhrW/xM3B3ffq7qWYZvdTa6sNsjDHysDJQQFiJVnT6Il6I5WQYGGtISAqO9M3eK35ma1Gww3R+Czkl2jHhQ6dYCI3RR";

const Scanner = ({ setCapture, capture }) => {
  const [scanFeedback, setScanFeedback] = useState(
    "Point the camera towards your ID or Passport"
  );
  const [boxColor, setBoxColor] = useState("red");

  useEffect(() => {
    configure();
  }, [capture]);

  /**
   * Check browser support, customize settings and load WASM SDK.
   */
  const configure = () => {
    if (!BlinkIDSDK.isBrowserSupported()) {
      alert("This browser is not supported!");
      return;
    }

    // 2. Create instance of SDK load settings with your license key
    const loadSettings = new BlinkIDSDK.WasmSDKLoadSettings(licenseKey);

    // [OPTIONAL] Change default settings

    // Show or hide hello message in browser console when WASM is successfully loaded
    loadSettings.allowHelloMessage = false;

    // In order to provide better UX, display progress bar while loading the SDK
    loadSettings.loadProgressCallback = (prog) => {};

    // Set absolute location of the engine, i.e. WASM and support JS files
    loadSettings.engineLocation =
      "https://blinkid.github.io/blinkid-in-browser/resources";

    // 3. Load SDK
    BlinkIDSDK.loadWasmModule(loadSettings).then(
      (sdk) => {
        startScan(sdk);
      },
      (error) => {
        alert("Failed to load SDK!");
        console.error("Failed to load SDK!", error);
      }
    );
  };

  const startScan = async (sdk) => {
    // 1. Create a recognizer objects which will be used to recognize single image or stream of images.
    //
    // Generic ID Recognizer - scan various ID documents
    // ID Barcode Recognizer - scan barcodes from various ID documents
    const genericIDRecognizer = await BlinkIDSDK.createBlinkIdRecognizer(sdk);

    // [OPTIONAL] Create a callbacks object that will receive recognition events, such as detected object location etc.
    const callbacks = {
      onQuadDetection: (quad) => drawQuad(quad),
      onDetectionFailed: () => setScanFeedback("Detection failed", true),
    };

    // 2. Create a RecognizerRunner object which orchestrates the recognition with one or more
    //    recognizer objects.
    const recognizerRunner = await BlinkIDSDK.createRecognizerRunner(
      // SDK instance to use
      sdk,
      // List of recognizer objects that will be associated with created RecognizerRunner object
      [genericIDRecognizer],
      // [OPTIONAL] Should recognition pipeline stop as soon as first recognizer in chain finished recognition
      false,
      // [OPTIONAL] Callbacks object that will receive recognition events
      callbacks
    );

    const cameraFeed = document.getElementById("camera-feed");

    // 3. Create a VideoRecognizer object and attach it to HTMLVideoElement that will be used for displaying the camera feed
    const videoRecognizer =
      await BlinkIDSDK.VideoRecognizer.createVideoRecognizerFromCameraStream(
        cameraFeed,
        recognizerRunner
      );

    // 4. Start the recognition and await for the results
    const processResult = await videoRecognizer.recognize();

    // 5. If recognition was successful, obtain the result and display it
    if (processResult !== BlinkIDSDK.RecognizerResultState.Empty) {
      const genericIDResults = await genericIDRecognizer.getResult();
      if (genericIDResults.state !== BlinkIDSDK.RecognizerResultState.Empty) {

        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        const width = cameraFeed.videoWidth;
        const height = cameraFeed.videoHeight;
        canvas.width = width;
        canvas.height = width * 0.65;
        context.drawImage(cameraFeed, 0, ((height / 2) - (width / 2 * 0.65)), width, (width * 0.65), 0, 0, width, (width * 0.65));
        const img = canvas.toDataURL('image/png');
        localStorage.setItem('document', img)
        const firstName =
          genericIDResults.firstName || genericIDResults.mrz.secondaryID;
        const lastName =
          genericIDResults.lastName || genericIDResults.mrz.primaryID;
        const dateOfBirth =
          "'" +
          (genericIDResults.dateOfBirth.year ||
            genericIDResults.mrz.dateOfBirth.year) +
          "-" +
          (genericIDResults.dateOfBirth.month ||
            genericIDResults.mrz.dateOfBirth.month) +
          "-" +
          (genericIDResults.dateOfBirth.day ||
            genericIDResults.mrz.dateOfBirth.day) +
          "'";
        const names = firstName.split(" ");
        localStorage.setItem(
          "results",
          JSON.stringify({
            firstName: names[0],
            middleName: names[1],
            lastName: lastName,
            dateOfBirth: dateOfBirth,
          })
        );
        setCapture(false);
      }
    } else {
      alert("Could not extract information!");
    }

    // 7. Release all resources allocated on the WebAssembly heap and associated with camera stream

    // Release browser resources associated with the camera stream
    videoRecognizer?.releaseVideoFeed();

    // Release memory on WebAssembly heap used by the RecognizerRunner
    recognizerRunner?.delete();

    // Release memory on WebAssembly heap used by the recognizer
    genericIDRecognizer?.delete();

    // Clear any leftovers drawn to canvas
    clearDrawCanvas();
  };

  /**
   * Utility functions for drawing detected quadrilateral onto canvas.
   */
  const drawQuad = (quad) => {
    clearDrawCanvas();
    setupColor(quad);
    setupMessage(quad);
  };

  const clearDrawCanvas = () => {
    setBoxColor("red");
  };

  const setupColor = (displayable) => {
    let color = "#FFFF00";
    if (displayable.detectionStatus === 0) {
      color = "#FF0000";
    } else if (displayable.detectionStatus === 1) {
      color = "#00FF00";
    }
    setBoxColor(color);
  };

  const setupMessage = (displayable) => {
    switch (displayable.detectionStatus) {
      case BlinkIDSDK.DetectionStatus.Fail:
        setScanFeedback("Scanning...");
        break;
      case BlinkIDSDK.DetectionStatus.Success:
      case BlinkIDSDK.DetectionStatus.FallbackSuccess:
        setScanFeedback("Detection successful");
        break;
      case BlinkIDSDK.DetectionStatus.CameraAtAngle:
        setScanFeedback("Adjust the angle");
        break;
      case BlinkIDSDK.DetectionStatus.CameraTooHigh:
        setScanFeedback("Move document closer");
        break;
      case BlinkIDSDK.DetectionStatus.CameraTooNear:
      case BlinkIDSDK.DetectionStatus.DocumentTooCloseToEdge:
      case BlinkIDSDK.DetectionStatus.Partial:
        setScanFeedback("Move document farther");
        break;
      default:
        console.warn(
          "Unhandled detection status!",
          displayable.detectionStatus
        );
    }
  };

  return (
    <View
      style={{
        flex: 1,
        width: window.innerWidth,
        height: window.innerHeight,
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        backgroundColor: "#000",
      }}
    >
      <View
        id="screen-scanning"
        style={{
          width: window.innerWidth,
          height: window.innerHeight,
          flex: 1,
        }}
      >
        <video
          id="camera-feed"
          playsInline
          style={{
            flex: 1,
            width: window.innerWidth,
            height: window.innerHeight,
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
        <View
          style={{
            position: "absolute",
            top: window.innerHeight / 2 - (window.innerWidth * 0.65) / 2,
            width: window.innerWidth,
            height: window.innerWidth * 0.65,
            borderWidth: 10,
            borderColor: boxColor,
            zIndex: 999,
          }}
        />
        <View
          style={{
            width: window.innerWidth - 40,
            marginHorizontal: 20,
            position: "absolute",
            top: 0,
            left: 0,
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
            borderRadius: 8,
            backgroundColor: "#000",
            minHeight: 50,
            zIndex: 999,
            marginTop: 20,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: "#fff",
              fontSize: 18,
            }}
          >
            {scanFeedback}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default Scanner;
