import React, { useEffect, useState } from "react";
import * as BlinkIDSDK from "@microblink/blinkid-in-browser-sdk";
import { Text, TouchableOpacity, View } from "react-native";
import Ionicons from "@expo/vector-icons/Ionicons";

const licenseKey =
  "sRwAAAYPa2F6aS52ZXJjZWwuYXBw3ElN4ZVG+c7j+faO/S59gYp6ovnqLyh0u5YSDY9193NaVQO546bAu28qa6olA6/HS22UDoJrvMqC+ijWYbqawP0DjjL/LGz1/kiUtPrQWxHF5l1G+qETxwqkDXAE3+tlnFp9UVhrW/xM3B3ffq7qWYZvdTa6sNsjDHysDJQQFiJVnT6Il6I5WQYGGtISAqO9M3eK35ma1Gww3R+Czkl2jHhQ6dYCI3RR";

const UploadScanner = () => {
  const [scanFeedback, setScanFeedback] = useState(
    "Point the camera towards your ID or Passport"
  );
  const [boxColor, setBoxColor] = useState("red");

  useEffect(() => {
    configure();
  }, []);

  /**
   * Check browser support, customize settings and load WASM SDK.
   */
  const configure = () => {
    if (!BlinkIDSDK.isBrowserSupported()) {
      alert("This browser is not supported!");
      return;
    }

    // 2. Create instance of SDK load settings with your license key
    const loadSettings = new BlinkIDSDK.WasmSDKLoadSettings(licenseKey);

    // [OPTIONAL] Change default settings

    // Show or hide hello message in browser console when WASM is successfully loaded
    loadSettings.allowHelloMessage = false;

    // In order to provide better UX, display progress bar while loading the SDK
    loadSettings.loadProgressCallback = (prog) => {};

    // Set absolute location of the engine, i.e. WASM and support JS files
    loadSettings.engineLocation =
      "https://blinkid.github.io/blinkid-in-browser/resources";

    // 3. Load SDK
    BlinkIDSDK.loadWasmModule(loadSettings).then(
      (sdk) => {
        document
          .getElementById("image-file")
          ?.addEventListener("change", (ev) => {
            ev.preventDefault();
            startScan(sdk, ev.target.files);
          });
      },
      (error) => {
        alert("Failed to load SDK!");
        console.error("Failed to load SDK!", error);
      }
    );
  };

  async function startScan(sdk, fileList) {
    // 1. Create a recognizer objects which will be used to recognize single image or stream of images.
    //
    // Generic ID Recognizer - scan various ID documents
    // ID Barcode Recognizer - scan barcodes from various ID documents
    const genericIDRecognizer = await BlinkIDSDK.createBlinkIdRecognizer(sdk);
    const idBarcodeRecognizer = await BlinkIDSDK.createIdBarcodeRecognizer(sdk);
    // 2. Create a RecognizerRunner object which orchestrates the recognition with one or more
    //    recognizer objects.
    const recognizerRunner = await BlinkIDSDK.createRecognizerRunner(
      // SDK instance to use
      sdk,
      // List of recognizer objects that will be associated with created RecognizerRunner object
      [genericIDRecognizer],
      // [OPTIONAL] Should recognition pipeline stop as soon as first recognizer in chain finished recognition
      false
    );
    // 3. Prepare image for scan action - keep in mind that SDK can only process images represented in
    //    internal CapturedFrame data structure. Therefore, auxiliary method "captureFrame" is provided.
    // Make sure that image file is provided
    let file = null;
    const inputImageFile = document.getElementById("image-file");
    const imageRegex = RegExp(/^image\//);
    for (let i = 0; i < fileList.length; ++i) {
      if (imageRegex.exec(fileList[i].type)) {
        file = fileList[i];
      }
    }
    if (!file) {
      alert("No image files provided!");
      // Release memory on WebAssembly heap used by the RecognizerRunner
      recognizerRunner?.delete();
      // Release memory on WebAssembly heap used by the recognizer
      genericIDRecognizer?.delete();
      idBarcodeRecognizer?.delete();
      inputImageFile.value = "";
      return;
    }
    const scanImageElement = document.getElementById("target-image");
    scanImageElement.src = URL.createObjectURL(file);
    await scanImageElement.decode();
    const imageFrame = BlinkIDSDK.captureFrame(scanImageElement);
    // 4. Start the recognition and await for the results
    const processResult = await recognizerRunner.processImage(imageFrame);
    // 5. If recognition was successful, obtain the result and display it
    if (processResult !== BlinkIDSDK.RecognizerResultState.Empty) {
      const genericIDResults = await genericIDRecognizer.getResult();
      if (genericIDResults.state !== BlinkIDSDK.RecognizerResultState.Empty) {
        const firstName =
          genericIDResults.firstName || genericIDResults.mrz.secondaryID;
        const lastName =
          genericIDResults.lastName || genericIDResults.mrz.primaryID;
        const dateOfBirth =
          "'" +
          (genericIDResults.dateOfBirth.year ||
            genericIDResults.mrz.dateOfBirth.year) +
          "-" +
          (genericIDResults.dateOfBirth.month ||
            genericIDResults.mrz.dateOfBirth.month) +
          "-" +
          (genericIDResults.dateOfBirth.day ||
            genericIDResults.mrz.dateOfBirth.day) +
          "'";
        const names = firstName.split(" ");
        localStorage.setItem(
          "results",
          JSON.stringify({
            firstName: names[0],
            middleName: names[1],
            lastName: lastName,
            dateOfBirth: dateOfBirth,
          })
        );
      }
      const idBarcodeResult = await idBarcodeRecognizer.getResult();
      if (idBarcodeResult.state !== BlinkIDSDK.RecognizerResultState.Empty) {
        const firstName =
          idBarcodeResult.firstName || idBarcodeResult.mrz.secondaryID;
        const lastName =
          idBarcodeResult.lastName || idBarcodeResult.mrz.primaryID;
        const dateOfBirth =
          "'" +
          (idBarcodeResult.dateOfBirth.year ||
            idBarcodeResult.mrz.dateOfBirth.year) +
          "-" +
          (idBarcodeResult.dateOfBirth.month ||
            idBarcodeResult.mrz.dateOfBirth.month) +
          "-" +
          (idBarcodeResult.dateOfBirth.day ||
            idBarcodeResult.mrz.dateOfBirth.day) +
          "'";
        const names = firstName.split(" ");
        localStorage.setItem(
          "results",
          JSON.stringify({
            firstName: names[0],
            middleName: names[1],
            lastName: lastName,
            dateOfBirth: dateOfBirth,
          })
        );
        setCapture(false);
      }
    } else {
      alert("Could not extract information!");
    }
    // 7. Release all resources allocated on the WebAssembly heap and associated with camera stream
    // Release memory on WebAssembly heap used by the RecognizerRunner
    recognizerRunner?.delete();
    // Release memory on WebAssembly heap used by the recognizer
    genericIDRecognizer?.delete();
    idBarcodeRecognizer?.delete();
    // Hide scanning screen and show scan button again
    inputImageFile.value = "";
  }

  return (
    <View
      style={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        backgroundColor: "#fff",
        marginTop: 12,
      }}
    >
      <input
        id="image-file"
        type="file"
        accept="image/*"
        capture="environment"
        style={{
          display: "none",
          visibility: "hidden",
          width: 0,
          height: 0,
          position: "absolute",
          top: -40,
          left: -40,
        }}
      />
      <TouchableOpacity
        onPress={() => document.getElementById("image-file").click()}
        style={{
          width: "100%",
          padding: 8,
          justifyContent: "center",
          alignItems: "center",
          height: 40,
          borderWidth: 2,
          borderRadius: 4,
          borderColor: "#2295F0",
          flexDirection: "row",
          borderRadius: 2,
          backgroundColor: "#fff",
        }}
      >
        <Ionicons name="cloud-upload-outline" size={16} color="#2295F0" />
        <Text
          style={{
            color: "#2295F0",
            fontSize: 14,
            fontWeight: 500,
            marginLeft: 10,
          }}
        >
          Upload ID or Passport
        </Text>
      </TouchableOpacity>
      <img id="target-image" style={{ marginTop: 12, backgroundColor: '#fff', maxWidth: (window.innerWidth * 0.8), height: 'auto' }} />
    </View>
  );
};

export default UploadScanner;
