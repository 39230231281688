import React, { useEffect, useState } from "react";
import { Text, View, TextInput, TouchableOpacity } from "react-native";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import TextField from "@mui/material/TextField";
import moment from "moment";
import Ionicons from "@expo/vector-icons/Ionicons";
import Scanner from "../components/Scan";
import UploadScanner from "../components/uploadScan";

export default function App() {
  const [showPicker, setShowPicker] = useState(false);
  const [capture, setCapture] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [dob, setDOB] = useState(null);
  const [image, setImage] = useState("");

  useEffect(() => {
    const values = localStorage.getItem("results");
    if (values) {
      const results = JSON.parse(values);
      setFirstName(results?.firstName ? results?.firstName : "");
      setMiddleName(results?.middleName ? results?.middleName : "");
      setLastName(results?.lastName ? results?.lastName : "");
      setDOB(results?.dateOfBirth ? results?.dateOfBirth : null);
      localStorage.removeItem("results");
    }
    const document = localStorage.getItem("document");
    if (document) {
      setImage(document);
      localStorage.removeItem("document");
    }
  }, [capture]);

  return (
    <>
      {capture ? (
        <Scanner setCapture={() => setCapture()} capture={capture} />
      ) : (
        <View style={{ flex: 1, alignItems: "center" }}>
          <View
            style={{
              width: "100%",
              height: 88,
              backgroundColor: "#0F6FBC",
              justifyContent: "flex-end",
            }}
          >
            <Text
              style={{
                color: "#fff",
                fontSize: 19,
                fontWeight: "bold",
                marginLeft: 16,
                marginBottom: 20,
              }}
            >
              Kazi Logo |
            </Text>
          </View>
          <View
            style={{
              backgroundColor: "#F7F7F",
              flex: 1,
              padding: 16,
              maxWidth: 500,
            }}
          >
            <Text
              style={{
                color: "#202020",
                marginTop: 8,
                fontWeight: 400,
                fontSize: 24,
              }}
            >
              Personal information
            </Text>
            <TouchableOpacity
              onPress={() => setCapture(true)}
              style={{
                width: "100%",
                padding: 8,
                justifyContent: "center",
                alignItems: "center",
                height: 40,
                backgroundColor: "#2295F0",
                marginTop: 12,
                flexDirection: "row",
                borderRadius: 2,
              }}
            >
              <Ionicons name="scan-outline" size={16} color="#fff" />
              <Text
                style={{
                  color: "#fff",
                  fontSize: 14,
                  fontWeight: 500,
                  marginLeft: 10,
                }}
              >
                Scan ID or Passport
              </Text>
            </TouchableOpacity>
            <Text
              style={{
                marginTop: 12,
                alignSelf: "center",
                fontWeight: 500,
              }}
            >
              or
            </Text>
            <UploadScanner />
            {image.length > 2 ? (
              <img
                src={image}
                style={{
                  maxWidth: window.innerWidth * 0.8,
                  height: "auto",
                  marginTop: 20,
                }}
              />
            ) : null}
            <Text
              style={{
                marginTop: 12,
                marginBottom: 4,
                color: "#5F5F5F",
                fontSize: 12,
                fontWeight: 500,
              }}
            >
              First name
            </Text>
            <TextInput
              value={firstName}
              onChangeText={(txt) => setFirstName(txt)}
              style={{
                height: 48,
                backgroundColor: "#fff",
                width: "100%",
                borderWidth: 1.5,
                borderColor: "#D7D7D7",
                outlineColor: "#2295F0",
                padding: 8,
                borderRadius: 2,
              }}
            />
            <Text
              style={{
                marginTop: 8,
                marginBottom: 4,
                color: "#5F5F5F",
                fontSize: 12,
                fontWeight: 500,
              }}
            >
              Middle name
            </Text>
            <TextInput
              value={middleName}
              onChangeText={(txt) => setMiddleName(txt)}
              style={{
                height: 48,
                backgroundColor: "#fff",
                width: "100%",
                borderWidth: 1.5,
                borderColor: "#D7D7D7",
                outlineColor: "#2295F0",
                padding: 8,
                borderRadius: 2,
              }}
            />
            <Text
              style={{
                marginTop: 8,
                marginBottom: 4,
                color: "#5F5F5F",
                fontSize: 12,
                fontWeight: 500,
              }}
            >
              Last name
            </Text>
            <TextInput
              value={lastName}
              onChangeText={(txt) => setLastName(txt)}
              style={{
                height: 48,
                backgroundColor: "#fff",
                width: "100%",
                borderWidth: 1.5,
                borderColor: "#D7D7D7",
                outlineColor: "#2295F0",
                padding: 8,
                borderRadius: 2,
              }}
            />
            <Text
              style={{
                marginTop: 8,
                marginBottom: 4,
                color: "#5F5F5F",
                fontSize: 12,
                fontWeight: 500,
              }}
            >
              Date of Birth
            </Text>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                open={showPicker}
                onOpen={() => setShowPicker(true)}
                onClose={() => setShowPicker(false)}
                disableFuture
                label="Select date"
                value={dob}
                onChange={(newValue) => {
                  setDOB(newValue);
                }}
                renderInput={(params) => (
                  <TextField style={{ display: "none" }} {...params} />
                )}
              />
            </LocalizationProvider>
            <TouchableOpacity
              onPress={() => {
                setShowPicker(true);
              }}
            >
              <TextInput
                value={dob ? moment(dob).format("YYYY-MM-DD") : ""}
                onChangeText={(txt) => setDOB(txt)}
                editable={false}
                style={{
                  height: 48,
                  backgroundColor: "#fff",
                  width: "100%",
                  borderWidth: 1.5,
                  borderColor: "#D7D7D7",
                  outlineColor: "#2295F0",
                  padding: 8,
                  borderRadius: 2,
                }}
              />
              <Ionicons
                name="chevron-down-outline"
                size={20}
                color="#00111F"
                style={{ position: "absolute", right: 20, bottom: 12 }}
              />
            </TouchableOpacity>
            <Text
              style={{
                marginTop: 8,
                marginBottom: 4,
                color: "#5F5F5F",
                fontSize: 12,
                fontWeight: 500,
              }}
            >
              Personal phone number
            </Text>
            <TextInput
              value={"+234 843 84 13"}
              style={{
                height: 48,
                backgroundColor: "#EEEEEE",
                width: "100%",
                borderWidth: 1.5,
                borderColor: "#EEEEEE",
                outlineColor: "#2295F0",
                padding: 8,
                borderRadius: 2,
              }}
            />
            <View
              style={{
                marginTop: 56,
                flexDirection: "row",
                alignContent: "space-between",
                justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  height: 48,
                  width: 160,
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TouchableOpacity>
                  <Text style={{ fontSize: 16, fontWeight: 700 }}>Back</Text>
                </TouchableOpacity>
              </View>
              <TouchableOpacity
                style={{
                  height: 48,
                  width: 160,
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#2295F0",
                  borderRadius: 2,
                }}
              >
                <Text style={{ fontSize: 16, fontWeight: 700, color: "#fff" }}>
                  Next
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      )}
    </>
  );
}
